import React from "react"
import {
  InboxIcon,
  SparklesIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline"
import frontImage from "../images/AdobeStock_183145772.png"
const APPHome = () => (
  <div className="relative bg-white pt-16 pb-6 overflow-hidden">
    <div className="relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span className="h-12 w-12 rounded-md flex items-center justify-center bg-red-default">
                <ShoppingCartIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="mt-6">
              <h2 className="text-3xl font-normal tracking-tight text-red-default">
                Shopsmart
              </h2>
              <p className="mt-4 text-lg text-gray-500">
                Shopsmart is changing. A new look. A new app and a whole lot
                more!
              </p>
              <div className="mt-4"></div>
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
              className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              src={frontImage}
              alt="Shopsmart in the app store"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default APPHome
